import CryptoJS from 'crypto-js';

function decryptImage(encrypted, key, iv) {
  const mediaKey = CryptoJS.enc.Utf8.parse(key);
  const mediaIv = CryptoJS.enc.Utf8.parse(iv);

  const decrypt = CryptoJS.AES.decrypt(encrypted, mediaKey, {
    iv: mediaIv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.NoPadding,
  });

  return decrypt.toString(CryptoJS.enc.Base64);
}

function rc4Decrypt(encryptedData) {
  let s = [];
  let k = [];
  let result = [];
  let i, j, temp;

  const key = process.env.VUE_APP_ENCRYPTION_IMAGE_KEY;

  for (i = 0; i < 256; i++) {
    s[i] = i;
    k[i] = key.charCodeAt(i % key.length);
  }

  j = 0;
  for (i = 0; i < 256; i++) {
    j = (j + s[i] + k[i]) % 256;
    temp = s[i];
    s[i] = s[j];
    s[j] = temp;
  }

  i = j = 0;
  for (let x = 0; x < encryptedData.length; x++) {
    i = (i + 1) % 256;
    j = (j + s[i]) % 256;
    temp = s[i];
    s[i] = s[j];
    s[j] = temp;
    result.push(encryptedData[x] ^ s[(s[i] + s[j]) % 256]);
  }

  let binary = '';
  const buffer = new Uint8Array(result);
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return `data:image/jpeg;base64,${btoa(binary)}`;
}

export { decryptImage, rc4Decrypt };
