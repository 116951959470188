import axios from 'axios';

const state = {
  categories: [],
  playings: {},
  contacts: [],
  advertisements: [],
  topBanners: {},
  centerBanners: {},
  slideBanners: {},
  dataList: {},
  announcements: {},
  notices: [],
};
const getters = {
  getCategories: state => state.categories,
  getPlayings: state => state.playings,
  getContacts: state => state.contacts,
  getAdvertisements: state => state.advertisements,
  getTopBanners: state => state.topBanners,
  getCenterBanners: state => state.centerBanners,
  getSlideBanners: state => state.slideBanners,
  getDataList: state => state.dataList,
  getAnnouncements: state => state.announcements,
  getNotices: state => state.notices,
};
const actions = {
  async fetchHome({ commit }) {
    const response = await axios.post(process.env.VUE_APP_API_BASE + '/homes');
    const data = response.data;
    commit('setCategories', data.data.categories);
    commit('setContacts', data.data.contacts);
    commit('setAdvertisements', data.data.advs);
    commit('setNotices', data.data.notices);

    let index = 0;
    index = response.data.data.advs.findIndex(item => item.advType === '顶飘广告');
    if (index >= 0) {
      commit('setTopBanners', data.data.advs[index]);
    }
    index = response.data.data.advs.findIndex(item => item.advType === '横幅广告');
    if (index >= 0) {
      commit('setCenterBanners', data.data.advs[index]);
    }
    index = response.data.data.advs.findIndex(item => item.advType === 'banner位');
    if (index >= 0) {
      commit('setSlideBanners', data.data.advs[index]);
    }
    index = response.data.data.advs.findIndex(item => item.advType === '图标广告');
    if (index >= 0) {
      commit('setDataList', data.data.advs[index]);
    }
    index = response.data.data.advs.findIndex(item => item.advType === '弹窗广告');
    if (index >= 0) {
      commit('setAnnouncements', data.data.advs[index]);
    }
    index = response.data.data.advs.findIndex(item => item.advType === '炮台广告');
    if (index >= 0) {
      commit('setPlayings', data.data.advs[index]);
    }
  },
  async handleCountClick({ commit }, payload) {
    const response = await axios.post(process.env.VUE_APP_API_BASE + '/adv_daily_click_count/add', payload);
    const data = response.data;
  },
};
const mutations = {
  setCategories: (state, categories) => (state.categories = categories),
  setPlayings: (state, playings) => (state.playings = playings),
  setContacts: (state, contacts) => (state.contacts = contacts),
  setAdvertisements: (state, advertisements) => (state.advertisements = advertisements),
  setTopBanners: (state, topBanners) => (state.topBanners = topBanners),
  setCenterBanners: (state, centerBanners) => (state.centerBanners = centerBanners),
  setSlideBanners: (state, slideBanners) => (state.slideBanners = slideBanners),
  setDataList: (state, dataList) => (state.dataList = dataList),
  setAnnouncements: (state, announcements) => (state.announcements = announcements),
  setNotices: (state, notices) => (state.notices = notices),
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
